<script setup>
import {computed, useAttrs} from "vue";

const props =defineProps({
  color :{
    type: String,
    default: ''
  },
  primary: {
    type: Boolean,
    default: false
  }
})
const attrClass = useAttrs();
const emit = defineEmits(['click'])
const getClass = computed(() => {
  let classes = 'text-stone-700 border border-stone-700 hover:bg-stone-100';

  if(props.color === 'primary' || props.primary ) {
    classes = 'bg-stone-500 text-white hover:bg-opacity-90 active:bg-stone-300'
  }

  if( !attrClass.class?.includes('px-') && !attrClass.class?.includes('py-') && !attrClass.class?.includes('p-')) {
    classes += ' px-6 py-3 lg:px-4 lg:py-2.5 active:bg-stone-300'
  }

  return classes;
})

</script>

<template>
  <div
    :class="getClass"
    tabindex="0"
    class="transition-all cursor-pointer outline-stone-800 rounded-full justify-center items-center inline-flex active:translate-x-0.5 active:translate-y-1"
    @keyup.enter="emit('click', $event)"
    @click="(e)=>{ emit('click', e) }"
  >
    <div class="justify-start items-center flex">
      <div class="text-center text-lg lg:text-base font-medium leading-tight">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
