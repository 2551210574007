<script setup>

import {provide} from "vue";

const props = defineProps({
  value:{
    type: Object,
    default(){
      return {}
    }
  },
  labelMinWidth: {
    type: String,
    default: ''
  },
})

const emit = defineEmits(['update:value'])
/**
 * @typedef {Object} FormModel
 * @property {Object} value
 * @property {Function} get
 * @property {Function} updateValue
 */
provide('formModel', {
  value: props.value,
  labelMinWidth: props.labelMinWidth,
  get: (key) => {
    return props.value[key]
  },
  updateValue: (key, value) => {
    const val = {...props.value}
    val[key] = value
    emit('update:value', val)
  }
})
</script>

<template>
  <div ref="icoForm">
    <slot />
  </div>
</template>

<style scoped>

</style>
