<script>
export default {
  props: {
    page: {
      type: Number,
      required: true,
      default:1
    },
    total: {
      type: Number,
      required: true,
      default: 500
    },
    perPage:{
      type: Number,
      default: 10
    }
  },
  emits: ['update:page', 'change'],
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    displayedPages() {
      // 예시로 최대 5개 페이지를 보여주게 설정하였습니다.
      // 원하는 대로 로직을 조정할 수 있습니다.
      let start = this.page - 2;
      if (start < 1) start = 1;
      let end = start + 4;
      if (end > this.totalPages) {
        end = this.totalPages;
        start = end - 4;
        if (start < 1) start = 1;
      }
      return Array.from({length: 5}, (_, i) => i + start);
    }
  },
  methods: {
    goToPage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.$emit('update:page', page);
      this.$emit('change', page);
    }
  }
};
</script>

<template>
  <div class="py-2 justify-center items-center flex w-full">
    <div class="rounded-full justify-start items-start gap-2 flex">
      <div
        class="w-6 h-6 rounded-full border border-stone-300 justify-center items-center flex cursor-pointer"
        @click="goToPage(1)"
      >
        <img
          src="/images/icons/arrow-left.svg"
          class="w-3 h-3 relative"
        >
        <img
          src="/images/icons/arrow-left.svg"
          class="w-3 h-3 relative -ml-2"
        >
      </div>
      <div
        class="w-6 h-6 rounded-full border border-stone-300 justify-center items-center flex cursor-pointer"
        @click="goToPage(page - 1)"
      >
        <img
          src="/images/icons/arrow-left.svg"
          class="w-3 h-3 relative"
        >
      </div>
    </div>

    <div
      v-for="pageNum in displayedPages"
      :key="pageNum"
      class="justify-start items-start flex cursor-pointer"
      @click="goToPage(pageNum)"
    >
      <div class="pager-container">
        <div
          :class="page === pageNum ? 'text-center text-stone-700 font-semibold leading-none' : 'pager'"
        >
          {{ pageNum > totalPages ? '--' : pageNum }}
        </div>
      </div>
    </div>

    <div class="rounded-full justify-start items-start gap-2 flex">
      <div
        class="w-6 h-6 bg-stone-500 rounded-full justify-center items-center flex cursor-pointer"
        @click="goToPage(page + 1)"
      >
        <img
          src="/images/icons/arrow-right.svg"
          class="w-3 h-3 relative"
        >
      </div>
      <div
        class="w-6 h-6 bg-stone-500 rounded-full justify-center items-center flex cursor-pointer"
        @click="goToPage(totalPages)"
      >
        <img
          src="/images/icons/arrow-right.svg"
          class="w-3 h-3 relative -mr-2 cursor-pointer"
        >
        <img
          src="/images/icons/arrow-right.svg"
          class="w-3 h-3 relative"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.pager-container {
  @apply px-3 rounded-md flex-col justify-start items-start gap-2.5 inline-flex text-stone-400
}

</style>
