import {computed, ref,  } from "vue";
import {useLocalStorage} from '@vueuse/core'
import axios from "@/utils/axios.js";

export const locale = useLocalStorage('pinia/session/language', 'kr')
const packages = ref({});
let debounce = null;
export const getLanguagePackage = async () => {

  const response = await axios.get('/api/translation-ai/package?locale=' + locale.value);
  if(response){
    packages.value[locale.value] = response.data;
  }else{
    packages.value[locale.value] = {};
  }
}

function template(text, options) {
  let rule = /\{([^}]+)}/g;
  if(!text){
    return text;
  }
  if (text.match(rule) === null) {
    return text;
  }

  text.match(rule).map((matched) => {
    let key = matched.slice(1, -1);

    if (options && options[key] !== undefined) {
      text = text.replace(matched, options[key])
    }
  });

  return text;

}

export const useLanguage = (option) => {
  let logs = {};
  if(option?.locale) {
    locale.value = option?.locale;
  }

  async function getTranslation(_locale) {

    // logs 안에 trim 한 뒤 빈값은 제거
    for (let key in logs) {
      if (logs[key].trim() === '') {
        delete logs[key];
      }
    }

    const pack = {
      locale: _locale,
      terms: logs
    };
    // white 을 이용해 비동기 데이터를 받아 처리한다.
    const response = await axios.post('/api/translation-ai/terms', pack)

    packages.value[_locale] = Object.assign(packages.value[_locale], response.data);
    logs = {};
  }

  const t = (key, options) => {
    if(!key){
      return '';
    }
    let localeValue = options?.locale || locale.value;

    if(localeValue === 'kr'){
      return template(key, options);
    }

    if(!packages.value[localeValue]){
      return template(key, options);
    }

    // 패키지는 있지만, 해당하는 데이터가 없다면 번역하여 가져온다.
    if (!packages.value[localeValue][key]) {

      // 임시로 데이터를 넣고!
      packages.value[localeValue][key] = key;
      logs[key] = key;
      if(debounce){
        clearTimeout(debounce);
      }
      // 번역이 필요한 데이터를 서버로 보내서 번역된 데이터를 가져온다.
      debounce = setTimeout( async () => {
        await getTranslation(localeValue);
      }, 2000);


      // 반응형으로 리턴하여 데이터 변경시 자동으로 반영되도록 한다.
      return computed(()=>{
        return template(packages.value[localeValue][key], options)
      }).value;
    }

    // 패키지도 있고 해당하는 데이터도 있다면 해당 데이터를 반환한다.
    return template( packages.value[localeValue][key] || key, options)
  }

  return {
    locale,
    t: t,
  }
}

export const languagePlugin = {
  install(app, option) {
    app.config.globalProperties.$t = useLanguage(option).t;
  }
}

export const __ = (text, eng) => {

  let value = useLanguage().locale.value;
  if (value === 'kr') {
    return text;
  } else {
    if (!eng) {
      return text;
    }
    return eng;
  }
};
export const $t = (text, opt = {}) => useLanguage().t(text, opt);
