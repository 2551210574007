
import {defineStore} from "pinia";
import LaravelPiniaPlugin from "@/utils/LaravelPiniaPlugin.js";
import axios from "@/utils/axios.js";
import {useLocalStorage} from "@vueuse/core";


let options = {
  ...LaravelPiniaPlugin()
}

export const usePopup = defineStore('popup', {
  state: () => ({
    ...options.state,
    ...{
      url : '/api/v1/popup',
      primaryKey: 'id',
      query: {}
    },
    readIds: useLocalStorage('pinia/popup/readId', [])
  }),
  getters: {
    ...options.getters,
  },
  actions: {
    ...options.actions,
    async getActivePopup(){
      try{
        const {data} = await axios.get(this.url + '?active=1')
        this.model = data
      }catch (e) {
        console.error(e);
      }
    },
    async readPopup(id){
      this.readIds = []; // 기존에 저장된 값을 삭제
      this.readIds.push({
        id: id,
        read_at: new Date()
      });
    },
    hasUnread(){
      return this.model?.id && !this.isRead(this.model.id);
    },
    isRead(id){
      if(!this.readIds.length){
        return false;
      }
      // read_at 에서 1일이 지나면 읽지 않은 것으로 처리
      let time = this.readIds.find(item => item.id === id);
      if(!time){
        return false;
      }
      // type == normal 일 경우 날짜 체크 안합니다.
      if(this.model?.type === 'normal'){
        return true;
      }
      // 읽은 날짜
      let read_at = new Date( time.read_at );
      // 현재 날짜
      let now = new Date();
      // 차이
      let diff = now - read_at;
      // 1일 이내
      return diff < 1000 * 60 * 60 * 20;
    }
  }
});
