import {errorStore} from "@/utils/useErrorStore.js";

export default {
  inject:{
    formModel: {
      default: null
    },
    field: {
      default: null
    }
  },
  props:{
    value: {
      type: null,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    icon: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
  },
  emits: ['update:value', 'submit'],
  computed:{
    getField() {
      return this.field;
    }
  },
  methods: {
    inputHandle(e) {

      const value = e.target ? e.target.value : e;
      this.$emit('update:value', value);
      errorStore.clear(this.getField)
      if(this.formModel){
        this.formModel.updateValue(this.getField, value);
      }
    },
    enterHandle(e) {
      if(e.key !== 'Enter'){
        return;
      }
      const value = e.target ? e.target.value : e;
      this.$emit('submit', value);
    }
  }
}
