
export function serializedQuery(query, prefix = '') {
  const str = [];

  for (let key in query) {
    if (query[key] !== undefined && query[key] !== null) {
      const currentKey = prefix ? `${prefix}[${key}]` : key;

      // query[key]이 원시 값이 아니면 (즉, 객체 또는 배열이면) 재귀적으로 처리
      if (typeof query[key] === 'object' && query[key] !== null && !(query[key] instanceof Date)) {
        str.push(serializedQuery(query[key], currentKey));
      } else {
        str.push(encodeURIComponent(currentKey) + '=' + encodeURIComponent(query[key]));
      }
    }
  }

  return str.join('&');
}
