<script>
import InputMixin from "@/components/form/InputMixin.js";
import {EyeIcon, EyeSlashIcon} from "@heroicons/vue/24/outline";

export default {
  name: 'IconicInput',
  components: {
    EyeIcon, EyeSlashIcon
  },
  mixins:[InputMixin],
  props:{
    clean: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      showPassword: false
    }
  },
  computed: {
    getType(){
      return this.showPassword ? 'text' : this.type;
    }
  },
  methods:{
    focus(){
      if(this.$refs.input) {
        this.$refs.input.focus()
      }
    },
  }
}
</script>

<template>
  <div
    class="w-full"
    :class="wrapperClass"
  >
    <div
      :class="{
        'border-none': clean,
        'border-b': !clean,
        'bg-stone-50': !clean,

      }"
      class="input-wrap w-full flex justify-start items-center gap-3 relative pl-4 pr-5 py-3 border-stone-700 overflow-hidden"
    >
      <img
        v-if="icon"
        alt=""
        :src="icon"
        class="w-5 h-5 relative"
      >
      <input
        ref="input"
        v-bind="$attrs"
        :type="getType"
        class="h-6 w-full text-stone-700 text-base font-normal leading-none placeholder-stone-400"
        :class="{
          'bg-stone-50': !clean,
        }"
        :placeholder="placeholder"
        :name="getField"
        :value="value"
        @input="inputHandle"
        @keyup.enter="enterHandle"
      >
      <img
        v-if="suffix"
        alt=""
        :src="suffix"
        class="w-5 h-5 relative justify-self-end"
        @click="enterHandle"
      >
      <eye-icon
        v-if="!suffix && type ==='password' && !showPassword"
        class="w-5 h-5 cursor-pointer"
        @click="showPassword = true"
      />
      <eye-slash-icon
        v-if="!suffix && type ==='password' && showPassword"
        class="w-5 h-5 cursor-pointer"
        @click="showPassword = false"
      />
    </div>
  </div>
</template>

<style scoped>
.bg-clear > .input-wrap, .bg-clear > .input-wrap > input {
  background-color: transparent;
}
</style>
