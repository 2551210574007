<script setup>
import {provide} from "vue";
import {$dialog} from "@/utils/pluginDialog.js";
import {$t} from "@/i18n/useLanguage.js";

const emit = defineEmits(['update:value', 'change']);
const props = defineProps({
  value: {
    type: [String, Number, Array],
    default: 0
  },
  // 다중 선택 가능 여부
  multiple: {
    type: Boolean,
    default: false
  },
  // 최대 선택 가능한 갯수
  maxSelection: {
    type: Number,
    default: 0
  },
  indexLabel: {
    type: Boolean,
    default: false
  },
  buttonType: {
    type: Boolean,
    default: false
  }
});

provide('radioGroup', {
  injected: true,
  updateValue(val) {
    if (props.multiple) {
      if (Array.isArray(val)) {
        emit('update:value', val)
      } else {
        const values = props.value.includes(val) ? props.value.filter(v => v !== val) : [...props.value, val];

        if(props.maxSelection > 0 && values.length > props.maxSelection) {
          $dialog($t('선택 가능한 옵션은 {count}개 입니다.', {
            count: props.maxSelection
          }));
          return;
        }

        emit('update:value', values)
      }
      return;
    }
    emit('update:value', val)
  },
  getValue() {
    return props.value;
  },
  hasValue(val) {
    if(props.multiple) {
      return props.value.includes(val);
    }
    return props.value === val;
  },
  isButton: props.buttonType,
})

</script>

<template>
  <div
    class="relative"
  >
    <slot />
  </div>
</template>

<style scoped>

</style>
