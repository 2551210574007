import {event, query} from "vue-gtag";
import vueGtage from 'vue-gtag';
import axios from "@/utils/axios.js";

export const gTagEvent = event;

let gtagId;
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV !== 'production') {
  gtagId = 'G-9XF320WRV7';
} else {
  gtagId = 'G-BE71KVDP36';
}
export default {
  install(app, router) {
    if(typeof window === 'undefined'){
      return;
    }

    app.use(vueGtage, {
      appName: 'Candleworks',
      pageTrackerScreenviewEnabled: true,
      config: { id: gtagId }
    }, router);

    gTagSetup();
  }
}
async function gTagSetup(){
  query('get', gtagId, 'client_id', async (clientID) => {
    const response = await axios.post('/api/statistic-center/set-session', {
      'ga_client_id': clientID,
    })


    return response.data;
  });
}
