<script>

import Popper from "vue3-popper";
import InputMixin from "@/components/form/InputMixin.js";

export default {
  name: 'IconicDatepicker',
  components: {
    Popper
  },
  mixins: [InputMixin],
  props: {
    lang: {
      type: String,
      default: 'kr'
    },
  },
  emits: ['update:value'],
  data() {
    const now = new Date();
    let daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
    let monthsOfYear = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

    if (this.lang !== 'kr') {
      daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }

    return {
      currentYear: now.getFullYear(),
      currentMonth: now.getMonth(),
      years: Array.from({length: 111}, (_, i) => now.getFullYear() - 100 + i),
      daysOfWeek,
      monthsOfYear,
      weeks: []
    };
  },
  watch: {
    value() {
      this.drawCalendar();
    },
    currentYear() {
      this.drawCalendar();
    },
    currentMonth() {
      this.drawCalendar();
    }
  },
  mounted() {
    if (this.value) {
      const date = new Date(this.value);
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth();
    }
    this.drawCalendar();
  },
  methods: {
    prevMonth() {
      if (this.currentMonth === 0) {
        this.currentYear--;
        this.currentMonth = 11;
      } else {
        this.currentMonth--;
      }
      this.drawCalendar();
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentYear++;
        this.currentMonth = 0;
      } else {
        this.currentMonth++;
      }
      this.drawCalendar();
    },
    drawCalendar() {
      const daysInMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
      const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1).getDay();

      let week = [];
      this.weeks = [];
      for (let i = 0; i < firstDayOfMonth; i++) {
        week.push({date: ''});
      }

      for (let i = 1; i <= daysInMonth; i++) {
        if ((i + firstDayOfMonth) % 7 === 1 && i !== 1) {
          this.weeks.push(week);
          week = [];
        }
        week.push({date: i});
      }
      this.weeks.push(week);
    },
    selectDay(day) {
      if (day.date === '') return;
      let month = this.currentMonth + 1;
      if (month < 10) month = `0${month}`;
      let dayOfMonth = day.date;
      if (dayOfMonth < 10) dayOfMonth = `0${dayOfMonth}`;

      this.$emit('update:value', `${this.currentYear}-${month}-${dayOfMonth}`);
    },
    isSelectedDay(day) {
      if (day.date === '') return false;
      const date = new Date(this.value);
      return this.currentYear === date.getFullYear() && this.currentMonth === date.getMonth() && day.date === date.getDate();
    }
  }
}

</script>
<template>
  <div class="w-full">
    <div
      class="w-full flex justify-start items-center gap-3 relative pl-4 pr-5 bg-stone-50 border-b border-stone-700"
    >
      <img
        v-if="icon"
        alt=""
        :src="icon"
        class="w-5 h-5 relative"
      >
      <Popper
        class="w-full"
      >
        <input
          :type="type"
          class="h-[48px] w-full text-stone-700 text-base font-normal leading-none placeholder-stone-400 bg-transparent border-0 cursor-pointer"
          :placeholder="placeholder"
          :name="field"
          :value="value"
          readonly
          @input="inputHandle"
        >
        <template #content="{close}">
          <table class="table table-auto p-2 bg-white ">
            <thead>
              <tr>
                <th
                  colspan="7"
                  class="p-2"
                >
                  <div class="flex gap-2 justify-between">
                    <button
                      class=" py-2 px-4"
                      @click="prevMonth"
                    >
                      <img
                        src="/images/icons/chevron.svg"
                        class="rotate-180"
                      >
                    </button>
                    <select
                      v-model="currentYear"
                      class="py-2"
                    >
                      <option
                        v-for="year in years"
                        :key="year"
                        :value="year"
                      >
                        {{ year }}년
                      </option>
                    </select>
                    <select
                      v-model="currentMonth"
                      class="py-2"
                    >
                      <option
                        v-for="(month, index) in monthsOfYear"
                        :key="index"
                        :value="index"
                      >
                        {{ month }}
                      </option>
                    </select>
                    <button
                      class=" py-2 px-4"
                      @click="nextMonth"
                    >
                      <img
                        src="/images/icons/chevron.svg"
                        class="rotate"
                      >
                    </button>
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  v-for="day in daysOfWeek"
                  :key="day"
                  class="p-2 w-12 text-center"
                >
                  {{ day }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="week in weeks"
                :key="week.id"
              >
                <td
                  v-for="day in week"
                  :key="day.id"
                  class="p-2 h-12 text-center hover:bg-gray-200 cursor-pointer"
                  :class="isSelectedDay(day) ? 'bg-stone-500 text-white rounded-lg' : ''"
                  @click="()=>{selectDay(day); close();}"
                >
                  {{ day.date }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Popper>
      <img
        v-if="suffix"
        alt=""
        :src="suffix"
        class="w-5 h-5 relative justify-self-end"
      >
    </div>
  </div>
</template>
<style scoped>
</style>
