
import CheckBoxInput from "@/components/form/CheckBoxInput.vue";
import DaumZipcodeInput from "@/components/form/DaumZipcodeInput.vue";
import HeartInput from "@/components/form/HeartInput.vue";
import IconicDatepicker from "@/components/form/IconicDatepicker.vue";
import IconicForm from "@/components/form/IconicForm.vue";
import IconicInput from "@/components/form/IconicInput.vue";
import IconicLabel from "@/components/form/IconicLabel.vue";
import IconicOption from "@/components/form/IconicOption.vue";
import IconicRadio from "@/components/form/IconicRadio.vue";
import IconicSelect from "@/components/form/IconicSelect.vue";
import ModalDialog from "@/components/form/ModalDialog.vue";
import RoundButton from "@/components/form/RoundButton.vue";
import SmallRoundButton from "@/components/form/SmallRoundButton.vue";
import StarInput from "@/components/form/StarInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import IconicPaginator from "@/components/form/IconicPaginator.vue";
import IconicTextarea from "@/components/form/IconicTextarea.vue";
import IconicFileInput from "@/components/form/IconicFileInput.vue";
import CwIcon from "@/components/form/CwIcon.vue";
import IconicRadioGroup from "@/components/form/IconicRadioGroup.vue";

/**
 * 기본 컴포넌트를 등록하는 플러그인
 *
 */
export const iconicPlugin = {
    install(app) {
      app.component('CheckBoxInput', CheckBoxInput)
      app.component('DaumZipcodeInput', DaumZipcodeInput)
      app.component('HeartInput', HeartInput)
      app.component('IconicDatepicker', IconicDatepicker)
      app.component('IconicForm', IconicForm)
      app.component('IconicInput', IconicInput)
      app.component('IconicLabel', IconicLabel)
      app.component('IconicOption', IconicOption)
      app.component('IconicRadio', IconicRadio)
      app.component('IconicRadioGroup', IconicRadioGroup)
      app.component('IconicSelect', IconicSelect)
      app.component('ModalDialog', ModalDialog)
      app.component('RoundButton', RoundButton)
      app.component('SmallRoundButton', SmallRoundButton)
      app.component('StarInput', StarInput)
      app.component('TextInput', TextInput)
      app.component('IconicPaginator', IconicPaginator )
      app.component('IconicTextarea', IconicTextarea)
      app.component('IconicFileInput', IconicFileInput)
      app.component('CwIcon', CwIcon)
    }
}
