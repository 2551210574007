<script>
import {defineComponent, h} from 'vue'
import InputMixin from "@/components/form/InputMixin.js";

export default defineComponent({
  name: "IconicSelect",
  mixins: [InputMixin],
  provide() {
    return {
      selector: this
    }
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:value', 'change', 'filtering', 'down'],
  data() {
    return {
      optionWidth: 0,
      opened: false,
      keyword: '',
      naviIndex: 0,
    }
  },
  computed: {
    getChildrenSlot(){
      if(!this.$slots.default){
        return []
      }
      return this.$slots.default()
    },
    getContentStyle() {
      return {
        width: this.optionWidth + 'px',
        zIndex: 100,
      }
    },
    getSelectedOption() {
      let options = this.getChildren(this.$slots?.default());
      // $slot 중에 value 값이 동일한 것을 찾아서 반환
      let option = options?.find((slot) => {
        return slot.props?.value === this.value;
      });
      if (!option && this.value !== undefined) {
        return this.value;
      }
      return option
    }
  },
  mounted() {
    this.updateTriggerWidth();
    window.addEventListener('resize', this.updateTriggerWidth);
    document.addEventListener('click', this.outsideClickListener);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateTriggerWidth);
    document.removeEventListener('click', this.outsideClickListener);
  },
  methods: {
    setNaviIndex(index){
      this.naviIndex = index;
    },
    /**
     * 재귀적으로 Iconic-option 만 찾아서 반환
     *
     * @param nodes {array}
     * @param options {array}
     * @returns {*[]}
     */
    getChildren(nodes, options = []) {
      if (nodes.length) {
        nodes.forEach((node) => {
          if (node.type.name === 'IconicOption') {
            options.push(node);
          } else if (node.children) {
            this.getChildren(node.children, options);
          }
        })
      }
      return options;
    },
    outsideClickListener(event) {
      if (!this.$el.contains(event.target)) {
        this.opened = false;
        this.naviIndex = 0;
      }
    },
    updateTriggerWidth() {
      this.optionWidth = this.$refs.reference?.clientWidth ?? 0
    },
    isSelected(value) {
      return this.value === value;
    },
    select(value) {
      // 같은 값이면 선택 취소
      this.$emit('update:value', value);
      // 값이 변화했을 때만 change 이벤트 발생
      if(this.value !== value){
        this.$emit('change', value);
      }
      this.opened = !this.opened;
      this.naviIndex = 0;
      this.$nextTick(() => {
        if(this.filterable){
          this.keyword = '';
          this.$emit('filtering', '');
        }
        this.updateTriggerWidth();
      })
    },
    submitNavigatedOption(){
      let options = this.getChildren(this.$slots?.default());
      let option = options[this.naviIndex - 1];
      if(option){
        this.select(option.props.value);
      }
    },
  },
  render() {
    let isEmptyValue = this.value === null || this.value === undefined;
    return h('div', {
      tabindex: '0',
      onClick: () => {
        this.opened = !this.opened;
        this.naviIndex = 0;
        this.$nextTick(() => {
          this.updateTriggerWidth();
        })
      },
    }, [
      h('div', {
        ref: 'reference',
        class: [
          'input-wrap',
          'w-full',
          'justify-between',
          'items-center',
          'gap-3',
          'flex',
          'transition-all',
          'duration-200',
          'overflow-hidden',
          'overflow-ellipsis',
          'whitespace-nowrap',
          'cursor-pointer',
          'relative',
          'h-full',
          'max-h-[48px]',
          (this.transparent) ? '' : 'bg-stone-50 pl-2.5',
          (this.opened) ? 'rounded-t-lg bg-stone-50 border-t' : ((this.rounded)?'rounded-lg':''),
        ],
        tabindex: '0',
      }, [
        this.icon ? h('img', {
          src: this.icon,
          class: ['w-5', 'h-5', 'relative'],
        }) : '',
        isEmptyValue ? h('div', {
          class: [
            'w-full',
            'text-stone-400',
            'text-base',
            'font-normal',
            'leading-none',
            'overflow-hidden',
            'overflow-ellipsis',
            'whitespace-nowrap',
          ],
        }, [
            (this.filterable ) ? h('input', {
              attrs:{
                tabindex: '0',
                autocomplete: 'off',
              },
              class: [
                'w-full',
                'bg-transparent',
                'text-stone-700',
                'text-base',
                'font-normal',
                'leading-none',
              ],
              placeholder: this.placeholder,
              value: this.keyword,
              onInput: (event) => {
                this.keyword = event.target.value;
                this.naviIndex = 0;
                this.opened = true;
                this.$emit('filtering', event.target.value);
              },
              onKeydown: (event) => {

                if(event.key === 'ArrowDown'){
                  this.naviIndex = Math.max(this.naviIndex + 1, 0)
                }
                // 위로 이동 화살키를 누르면 emit
                if(event.key === 'ArrowUp'){
                  this.naviIndex = Math.max(this.naviIndex - 1, 0);
                }

                if(event.key === 'Enter'){
                  this.submitNavigatedOption();
                }
              }
            }) : this.placeholder
          ]) : this.getSelectedOption,

        h('div', {
          class: [
            'shrink-0',
            'p-2.5',
            'rounded-full',
            'justify-start',
            'items-start',
            'flex',
            'transition-all',
            'duration-300',
            'ml-auto',
            this.opened ? 'arrow__down' : 'rotate-90'
          ],
        }, [
          (this.filterable && !isEmptyValue) ? h('div', {
            class: [
              'shrink-0',
              'p-2.5',
              'rounded-full',
              'justify-end',
              'items-start',
              'flex',
              'transition-all',
              'duration-300',
              'max-w-[40px]',
            ],
            onClick: (event) => {
              event.stopPropagation();
              this.$emit('update:value', null);
              this.$emit('change', null);
            },
          }, [
            h('img', {
              src: '/images/icons/close.svg',
            }),
          ]) : h('img', {
            src: '/images/icons/chevron.svg',
          }),

        ]),
      ]),
      h('div', {
        slot: 'content',
        class: [
          'iconic-select-wrapper',
          'fadeInDown',
          'max-h-[300px]',
          'overflow-y-auto',
          'p-4', 'bg-stone-50', 'rounded-b-lg', 'w-full', 'leading-8', 'text-base', 'absolute',
          this.opened ? 'z-100' : 'hidden',
          this.opened ? 'shadow-lg border-t border-stone-200' : '',
        ],
        style: this.getContentStyle,
      }, this.getChildrenSlot),
    ]);
  },
})
</script>


<style scoped>
.bg-clear > .input-wrap, .bg-clear > .input-wrap > input {
  background-color: transparent;
}


/*noinspection ALL*/
.arrow__down {
  transform: rotate(-90deg);
}

/*noinspection ALL*/
.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}


@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
