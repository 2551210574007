
import {defineStore} from "pinia";
import LaravelPiniaPlugin from "@/utils/LaravelPiniaPlugin.js";
import axios from "@/utils/axios.js";
import {$dialog} from "@/utils/pluginDialog.js";

let options = LaravelPiniaPlugin();
/**
  * @typedef {import('types.js').BoardPost}
 *
 *
 */
export const useNoticeBoard = defineStore('notice', {
  state: () => ({
    ...options.state,
    ...{
      primaryKey: 'id',
      url : '/api/v1/notice',
      query: {
        sort: 'created_at',
        order: 'desc',
        perPage: 5
      },
      paginate: {
        /** @type {BoardPost[]} */
        data: []
      },
    }
  }),
  getters: {
    ...options.getters,
  },
  actions: {
    ...options.actions,
    async deleteFile(id, field='file'){
      const response = await axios.delete(`/api/v1/notice/${this.model.id}/${field}`, {
        data:{
          id: id
        }
      });

      if(response.data?.message){
        $dialog(response.data.message);
      }
    }
  }
});
