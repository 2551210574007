<script setup>
import {computed, useAttrs} from "vue";

const props =defineProps({
  color :{
    type: String,
    default: ''
  },
  primary: {
    type: Boolean,
    default: false
  }
})
const attrClass = useAttrs()
const emit = defineEmits(['click'])
const getClass = computed(() => {
  let classes = 'text-stone-700 border border-stone-700 hover:bg-stone-100';

  if(props.color === 'primary' || props.primary ) {
    classes = 'bg-stone-500 text-white hover:bg-opacity-90'
  }

  // px-, py-, p- 으로 시작하는 클래스가 없다면 p-2 추가
  if(!attrClass.class?.includes('px-') && !attrClass.class?.includes('py-') && !attrClass.class?.includes('p-')) {
    classes += ' p-2'
  }

  return classes;
})

</script>

<template>
  <button
    type="button"
    :class="getClass"
    tabindex="0"
    class="transition-all cursor-pointer outline-stone-800 rounded-full justify-center items-center inline-flex active:shadow-md active:translate-x-0.5 active:translate-y-1"
    @keyup.enter="emit('click', $event)"
    @click="(e)=>{ emit('click', e) }"
  >
    <span class="justify-start items-center flex">
      <span class="text-center font-medium leading-tight">
        <slot />
      </span>
    </span>
  </button>
</template>

<style scoped>

</style>
