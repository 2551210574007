<script>
export default {
  name: 'HeartInput',
  props:{
    value: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  methods:{
    toggle(){
      this.$emit('input', !this.value)
    }
  }
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M14 8.39337C11.6667 2.91652 3.5 3.49986 3.5 10.4999C3.5 17.4999 14 23.3332 14 23.3332C14 23.3332 24.5 17.4999 24.5 10.4999C24.5 3.49986 16.3333 2.91652 14 8.39337Z"
      fill="#887C77"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.70527 7.19857C5.10501 7.91214 4.66665 8.98339 4.66665 10.5C4.66665 13.4554 6.92806 16.3613 9.52507 18.6697C10.7862 19.7907 12.0527 20.7059 13.0065 21.3418C13.4007 21.6046 13.7395 21.8184 14 21.9779C14.2604 21.8184 14.5993 21.6046 14.9935 21.3418C15.9473 20.7059 17.2138 19.7907 18.4749 18.6697C21.0719 16.3613 23.3333 13.4554 23.3333 10.5C23.3333 8.98339 22.895 7.91214 22.2947 7.19857C21.6911 6.48101 20.8642 6.05316 19.9586 5.92266C18.1363 5.66007 16.0277 6.61056 15.0733 8.85076C14.89 9.28096 14.4676 9.56016 14 9.56016C13.5324 9.56016 13.1099 9.28096 12.9267 8.85076C11.9723 6.61056 9.86368 5.66007 8.04138 5.92266C7.13573 6.05316 6.30889 6.48101 5.70527 7.19857ZM14 23.3333C13.4334 24.3532 13.4331 24.353 13.4328 24.3528L13.4319 24.3523L13.4294 24.351L13.4217 24.3466L13.395 24.3316C13.3807 24.3235 13.3627 24.3132 13.3409 24.3008C13.328 24.2934 13.3139 24.2852 13.2984 24.2763C13.2155 24.2284 13.0964 24.1586 12.9462 24.068C12.646 23.8869 12.2208 23.6223 11.7122 23.2832C10.6973 22.6066 9.33879 21.626 7.97489 20.4137C5.3219 18.0555 2.33331 14.5446 2.33331 10.5C2.33331 8.51662 2.91578 6.88994 3.91969 5.69653C4.92024 4.5071 6.28089 3.81891 7.70858 3.61318C9.96008 3.28874 12.3977 4.15864 14 6.14066C15.6023 4.15864 18.0399 3.28874 20.2914 3.61318C21.7191 3.81891 23.0797 4.5071 24.0803 5.69653C25.0842 6.88994 25.6666 8.51662 25.6666 10.5C25.6666 14.5446 22.6781 18.0555 20.0251 20.4137C18.6612 21.626 17.3027 22.6066 16.2878 23.2832C15.7791 23.6223 15.354 23.8869 15.0537 24.068C14.9035 24.1586 14.7844 24.2284 14.7016 24.2763C14.6601 24.3002 14.6277 24.3187 14.605 24.3316L14.5783 24.3466L14.5705 24.351L14.5681 24.3523L14.5672 24.3528C14.5669 24.353 14.5666 24.3532 14 23.3333ZM14 23.3333L14.5666 24.3532C14.2142 24.5489 13.7858 24.5489 13.4334 24.3532L14 23.3333Z"
      fill="#887C77"
    />
  </svg>
</template>

<style scoped>

</style>
