
function ETagStoreManager() {
  const ETagStore = new Map();

  this.cleanTags = function(etag) {
    // "W/etag" -> etag
    return etag.replaceAll(/W\/|"/ig, '');
  }
  this.setTag = function(url, etag, data) {
    etag = this.cleanTags(etag);
    ETagStore.set(url, etag);
    ETagStore.set(etag, data);
  }
  this.removeTag = function(url) {
    const etag = ETagStore.get(url);
    ETagStore.delete(url);
    ETagStore.delete(etag);
  }
  this.getTag = function(url) {
    return ETagStore.get(url);
  }
  this.getData = function(etag) {
    let key = this.cleanTags(etag);
    return ETagStore.get(key) ;
  }
}
const ETagStore = new ETagStoreManager();
export default ETagStore;
