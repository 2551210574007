<script setup>

import {ref} from 'vue';

const isOpen = ref(false);

const emit = defineEmits(['update:isOpen']);
</script>

<template>
  <div>
    <div class="flex gap-4 items-center transition-all duration-200">
      <slot name="title" />
      <button
        class="w-5 h-5 relative justify-self-end"
        @click="()=>{isOpen = !isOpen; emit('update:isOpen', isOpen)}"
      >
        <img
          src="/images/icons/chevron.svg"
          alt=""
          class="transition-transform duration-200"
          :class="{
            'rotate-180': isOpen
          }"
        >
      </button>
    </div>
    <transition
      name="custom"
      enter-active-class="transition duration-200 ease-out"
      leave-active-class="transition duration-200 ease-in"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
    >
      <div
        v-if="isOpen"
        class="overflow-hidden py-4"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<style scoped>

</style>
