import axios from 'axios';
import {errorStore} from "@/utils/useErrorStore.js";
import {$dialog} from "@/utils/pluginDialog.js";
import {$t} from "@/i18n/useLanguage.js";
import router from "@/routes/index.js";
import ETagStore from "@/utils/eTagStore.js";

// process.env.NODE_ENV === 'production' ? 'https://candleworks.com' : 'https://candleworks.test'

const isSSR = typeof window === 'undefined';
// eslint-disable-next-line no-undef
let host = process.env.NODE_ENV === 'production' ? 'https://candleworks.co.kr' : 'https://candleworks.test';

if(!isSSR){
    host = undefined;
}

const instance = axios.create({
    baseURL: host,
    timeout: 10000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    withXSRFToken: true,
    withCredentials: true,
})


// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // 에러 메시지 초기화
    errorStore.set({});

    if(ETagStore.getTag(config.url)){
        config.headers['If-None-Match'] = ETagStore.getTag(config.url);
    }

    return config;
});
let bouncer;

instance.interceptors.response.use(function (response) {
    // 캐싱을 위한 셋팅
    if(response.headers.etag){
        ETagStore.setTag(response.config.url, response.headers.etag, response.data);
    }
    return response;
}, (error) => {
    if(isSSR){
        return Promise.reject(error);
    }
    // Do something with request error
    const {response} = error;
    if(!response){
        return Promise.reject(error);
    }
    let etag = response.headers.etag;
    if(response.status === 304 && etag){

        let data = ETagStore.getData(etag);
        if(!data){
            ETagStore.removeTag(response.config.url);
            return Promise.reject(error);
        }
        response.data = data;

        return Promise.resolve(response);
    }
    if(response.status === 401){
        if(bouncer){
            clearTimeout(bouncer);
        }
        bouncer = setTimeout(async () => {

            await $dialog($t('로그인이 필요합니다.'))
            await router.push({name: 'Login', query: {redirect: router.currentRoute.value.fullPath}});

        }, 300);
    }
    if(response.status === 422){
        errorStore.set(response.data);
        errorStore.goToFirst();
    }

    if(response.status === 404){
        $dialog($t('페이지를 찾을 수 없거나 접근할 수 없는 페이지 입니다.'))
        router.push({name: 'NotFound'});
    }

    return Promise.reject(error);
});

export default instance;
