function createLoader(binding, el) {
  if (binding.value === false) return;
  // 로딩 div를 생성
  const loadingDiv = document.createElement('div');
  loadingDiv.className = 'loading-div';

  const spinner = document.createElement('span');
  const spinnerStyle = {
    /* 애니메이션 추가 */
    borderRadius: '50%',
    borderTop: '4px solid #606060',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  }

  loadingDiv.appendChild(spinner);

  Object.keys(spinnerStyle).forEach(key => {
    spinner.style[key] = spinnerStyle[key];
  });
  // 로딩 div의 스타일 정의
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    background: 'rgba(230, 230, 230, 0.5)',
    borderRadius: '2rem',
    animation: 'fadeIn 0.3s both' /* fade-in 애니메이션 추가 */
  };
  Object.keys(styles).forEach(key => {
    loadingDiv.style[key] = styles[key];
  });

  el.style.position = 'relative'; // 로딩 div의 부모 요소에 대한 위치 설정
  el.appendChild(loadingDiv);

  el.__loadingDiv__ = loadingDiv; // 차후 업데이트 및 unmount에서 사용하기 위해 저장
}
export const loadingDirective = {
  updated(el, binding) {
    if(el.__loadingDiv__) {
      el.removeChild(el.__loadingDiv__);
      delete el.__loadingDiv__;
    }
    createLoader(binding, el);
  },
  unmounted(el) {
    if(el.__loadingDiv__) {
      el.removeChild(el.__loadingDiv__);
      delete el.__loadingDiv__;
    }
  }
};
