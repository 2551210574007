<script>

export default {
  name: "IconicOption",
  inject: ["selector"],
  props: {
    value: {
      type: null,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    getClasses(){
      return {
        "iconic-selected-option": this.selector.value === this.value,
        "text-gray-400": this.readonly,
        "bg-gray-200" : this.isNavigated
      }
    },
    isNavigated(){
      let naviIndex = this.selector.naviIndex;
      if(naviIndex ===  0){
        return false;
      }

      let friends = this.selector.getChildren(this.selector.$slots?.default());
      const last = friends.length;

      if(naviIndex >= last){
        this.selector.setNaviIndex(last);
        naviIndex = last;
      }

      let number = friends.findIndex((slot) => {
        return slot.props?.value === this.value;
      })

      return naviIndex === number+1;
    }
  },
  methods:{
    select(){
      if(this.readonly){
        return;
      }
      this.selector.select(this.value);
    }
  }
}
</script>

<template>
  <div
    class="cursor-pointer text-base overflow-hidden overflow-ellipsis whitespace-nowrap"
    :class="getClasses"

    @click.stop="select"
  >
    <slot />
  </div>
</template>

<style scoped>
/*noinspection ALL*/
.iconic-select-wrapper .iconic-selected-option{
  font-weight: 600;
}
</style>
