<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "@/plugins/axios";

const response = ref({data: []});
// 처음 6개만 보여주기
const items = computed(() => {
  return response.value?.data?.filter(item=>{
    // jpg 또는 jpeg 파일만 보여주기
    return item?.media_url?.includes('jpeg') || item?.media_url?.includes('jpg');
  }).slice(0, 6) || [];
});
function getInstagram(){
  axios.get('/api/v1/insta-feeds' )
    .then(res => {
      response.value = res.data;
    })
}

onMounted(() => {
  getInstagram();
})

function openWindow(url) {
  window.open(url, '_blank');
}
</script>

<template>
  <section class="instagram-feed w-full max-w-screen-2xl lg:mx-auto lg:mt-8 lg:mb-20 lg:px-10">
    <div class="lg:py-4 grow shrink basis-0 text-stone-700 text-lg lg:text-[40px] font-normal font-['Pretendard'] leading-10 ">
      Instagram
    </div>
    <div class="grid grid-cols-3 lg:grid-cols-6 gap-1 lg:py-2">
      <template
        v-for="insta in items"
        :key="insta.id"
      >
        <figure class="aspect-square">
          <img
            class="w-full h-full object-cover"
            :src="insta.media_url"
            alt=""
            @click="openWindow(insta.permalink)"
          >
        </figure>
      </template>
    </div>
  </section>
</template>

<style scoped>

</style>
