
export default [
  {
    path: '/my-page',
    component: () => import('./MyPage.vue'),
    children: [
      {
        path: '',
        name: 'MyPage',
        component: () => import('./MyPageDashboard.vue'),
      },
      {
        path: 'account',
        name: 'MyPageAccount',
        component: () => import('./MyPageAccount.vue'),
      },
      {
        path: 'notification',
        name: 'MyPageNotification',
        component: () => import('./MyPageNotification.vue'),
      },
      {
        path: 'order',
        name: 'MyPageOrder',
        component: () => import('./MyPageOrder.vue'),
      },
      {
        path: 'order/:orderId',
        name: 'MyPageOrderShow',
        component: () => import('./MyPageOrderShow.vue'),
        props: true,
      },
      {
        name: "MyPageStoreOrder",
        path: "store-order",
        component: () => import("./MyPageStoreOrder.vue"),
      },
      {
        name: "MyPageTSSA",
        path: "tssa",
        component: () => import("./MyPageTSSA.vue"),
      },
      {
        name: "MyPageInquiry",
        path: "inquiry",
        component: () => import("./MyPageInquiry.vue"),
      },
      {
        name: "MyPageProductInquiry",
        path: "product-inquiry",
        component: () => import("./MyPageProductInquiry.vue"),
      },
      {
        name: "MyPageProductReview",
        path: "product-review",
        component: () => import("./MyPageProductReview.vue"),
      },
      {
        name: "MyPageWishList",
        path: "wish-list",
        component: () => import("./MyPageWishList.vue"),
      },
      {
        name: "MyPageInquiryEdit",
        path: "inquiry/:id",
        component: () => import("./MyPageInquiryEdit.vue"),
      },
      {
        name: "MyPageInquiryShow",
        path: "inquiry/:id/show",
        component: () => import("./MyPageInquiryShow.vue"),
        props: true,
      },
      {
        name: "MyPageCoupon",
        path: "coupon",
        component: () => import("./MyPageCoupon.vue"),
      },
      {
        name: "MyPagePoint",
        path: "point",
        component: () => import("./MyPagePoint.vue"),
      },
    ]
  }
]
