import {defineStore} from "pinia";
let helper;
export function isDesktop() {
  if(helper){
    return helper.isDesktop;
  }
  if(typeof window === 'undefined'){
    // eslint-disable-next-line no-undef
    return global.deviceType === 'desktop';
  }
  return window?.innerWidth > 1000;
}

export default {
  // eslint-disable-next-line no-unused-vars
  install(){
    helper = useScreenHelper();
  }
}

export const useScreenHelper = defineStore('window', {
  state: () => ({
    width: 1024
  }),
  getters: {
    isDesktop(){
      if(typeof window === 'undefined'){
        // eslint-disable-next-line no-undef
        return global.deviceType === 'desktop';
      }
      this.width = window.innerWidth;
      return this.width > 1024;
    },
  },
  actions: {
    setWidth() {
      this.width = window.innerWidth;
    },
    registerResizeEvent() {
      if(window)
        window.addEventListener('resize', this.setWidth);
    },
    unregisterResizeEvent() {
      if(window)
        window.removeEventListener('resize', this.setWidth);
    }
  }

});
