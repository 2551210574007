import axios from "@/utils/axios.js";
import {defineStore} from "pinia";
import {useSessionStore} from "@/utils/useSessionStore.js";

export const useNotification = defineStore('notification', {
  state: () => ({
    response: {
      data: []
    },
  }),
  getters: {
    getData: (state) => {
      return state.response?.data;
    },
    getUnreadCount: (state) => {
      if(!state.response?.data){
        return 0;
      }
      return state.response?.data?.filter(item => !item.read_at).length;
    }
  },
  actions:{
    async get(){
      const sessionStore = useSessionStore();
      if(!sessionStore.authenticated){
        return;
      }
      const {data} = await axios.get('/api/v1/notification?mb_id=' + sessionStore.user.mb_id);
      this.response = data;
    },

    async read(id){
      await axios.get(`/api/v1/notification/${id}/read-at`);
      this.get();
    },

    async deleteAll(){
      await axios.delete(`/api/v1/notification/all`);
      this.get();
    },

    async delete(id){
      await axios.delete(`/api/v1/notification/${id}`);
      this.get();
    }
  }
});
