<template>
  <div class="relative w-full">
    <div class="flex gap-4 items-center">
      <iconic-input
        :placeholder="placeholder"
        class="w-full"
        :value="value"
        readonly
        @click.stop="show"
      />
      <round-button
        color="primary"
        class="w-3/5 h-full"
        @click.stop="show"
      >
        우편번호 검색
      </round-button>
    </div>
    <div
      ref="finderWrap"
      class="absolute border border-stone-300 shadow-lg hidden z-10 min-w-[100%]"
      style=""
    >
      <div
        ref="finder"
        class="w-full h-full relative"
      />
    </div>
  </div>
</template>

<script>

let instance;
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '우편번호찾기'
    }
  },
  emits: ['input', 'selected', 'show', 'update:value'],
  data() {
    return {
      loading: false,
      isShow: false,
      loaded: false
    }
  },
  mounted() {
    document.addEventListener('click', this.closeEvent)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeEvent)
  },
  methods: {
    closeEvent(e) {
      if (this.$refs.finderWrap.contains(e.target)) {
        return;
      }
      this.hiddenFinder();
    },
    open() {
      const _this = this;

      var currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
      if(instance){
        this.showFinder();
        return;
      }
      // eslint-disable-next-line no-undef
      instance = new daum.Postcode({
        oncomplete(data) {
          _this.$emit('input', data.zonecode);
          _this.$emit('update:value', data.zonecode);
          _this.$emit('selected', data);
          _this.hiddenFinder();
          document.body.scrollTop = currentScroll;

        },
        onresize(size) {
          _this.$refs.finder.style.height = size.height + 'px';
        },
        width: '100%',
        height: '100%'
      });
      // eslint-disable-next-line no-undef
      daum.postcode.load(() => {
        instance.embed(this.$refs.finder);
        this.showFinder()
      });
    },
    async show() {

      if (!this.loaded) {
        return await this.loadScript();
      }

      this.open();
    },
    showFinder() {
      //this.isShow = true;
      this.$emit('show', true);
      this.$refs.finderWrap.classList.remove('hidden');
    },
    hiddenFinder() {
      //this.isShow = false;
      this.$emit('show', false);
      this.$refs.finderWrap.classList.add('hidden');
    },
    async loadScript() {
      return await new Promise((resolve, reject) => {
        try {
          if (document.querySelector('script[src="https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"]') !== null) {
            this.open();
            return;
          }
          this.loading = true;
          let script = document.createElement('script');
          script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
          script.onload = () => {

            this.open();

            this.loaded = true;
            resolve();
          };
          document.body.appendChild(script);
        } catch (e) {
          reject(e);
        }
      })
    },

  }
}
</script>
