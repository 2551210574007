import {reactive} from "vue";
import {$dialog} from "@/utils/pluginDialog.js";

export const errorStore = reactive({
  errors: {},
  moveable: true,
  set(errors) {
    // 키값이 files.0.name 이런식으로 되어 있으면, files 라는 키값을 가진 배열에 넣어줍니다.
    for (let key in errors) {
      if (key.includes('.')) {
        let fields = key.split('.');
        if (!errors[fields[0]]) {
          errors[fields[0]] = [];
        }
        errors[fields[0]].push(errors[key]);
        delete errors[key];
      }
    }
    errorStore.errors = errors;
  },
  get(field) {
    return errorStore.errors[field] || null;
  },
  clear(field) {
    if (field) {
      delete errorStore.errors[field];
      return;
    }
    errorStore.errors = {};
  },
  dialogErrorMessage() {
    let message = '';
    for (let key in errorStore.errors) {
      message += errorStore.errors[key][0] + '\n';
    }
    $dialog(message);
  },
  /**
   * 422 에러 발생시, 발생한 그 인풋의 위치로 스크롤을 이동합니다.
   */
  goToFirst() {
    // ssr 에서는 window 가 없으므로, 실행하지 않습니다.
    if (typeof document === 'undefined') {
      return;
    }
    if (!errorStore.moveable) {
      // 에러가 발생한 인풋의 위치로 스크롤을 이동하지 않도록 설정되어 있으면, 다이얼로그로 에러를 보여 줍니다.
      errorStore.dialogErrorMessage();
      return;
    }
    const fieldNames = Object.keys(errorStore.errors);
    // 이 에러 필드 중에 가장 위에 있는 것이 무엇인지 포지션을 찾습니다.

    const positions = [];
    // 그 에러 필드의 인풋 엘리먼트를 찾습니다. props.field + '_validate-error'
    for (let fieldName of fieldNames) {
      // filedName 이 . 이 포함되어 있으면, 해당 필드가 배열이라는 뜻입니다.
      // 그러므로, 해당 필드의 첫번째 인풋 엘리먼트를 찾아야 합니다.
      let errorElement;
      if (fieldName.includes('.')) {
        const fields = fieldName.split('.');
        errorElement = document.querySelector(`[id=${fields[0]}_validate-error]`);
      }else{
        errorElement = document.querySelector(`[id=${fieldName}_validate-error]`);
      }

      if (errorElement) {
        positions.push(errorElement.getBoundingClientRect().y);
      }
    }

    if(!positions.length){
      errorStore.dialogErrorMessage();
      return;
    }

    // 그 인풋 엘리먼트로 스크롤을 이동합니다.
    window.scrollBy({
      top: Math.min(...positions) - 120,
      behavior: 'smooth'
    });
  }
});

