<script setup>

import {errorStore} from "@/utils/useErrorStore.js";
import {computed, inject} from "vue";

const props = defineProps({
  value: {
    type: [Boolean, String, Number],
    default: false
  },
  activeValue: {
    type: [Boolean, String, Number],
    default: true
  },
  inactiveValue: {
    type: [Boolean, String, Number],
    default: false
  },
  field: {
    type: String,
    default: ''
  },
  totalCount: {
    type: Number,
    default: 0
  },
  selectedCount: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['update:value', 'change'])

function hasErrorMessages() {
  return errorStore.get(props.field) && errorStore.get(props.field).length > 0
}
const isChecked = computed(() => {
  if(checkGroup.injected){

    return checkGroup.values().filter((v)=>v === props.activeValue).length > 0
  }
  return props.value === props.activeValue
})
function handle() {
  let value;
  if (!isChecked.value) {
    value = props.activeValue;
    checkGroup.injected && checkGroup.add(value)
  } else {
    value = props.inactiveValue;
    checkGroup.injected && checkGroup.remove(props.activeValue)
  }

  emit('update:value', value)
  emit('change', value)
}

const isMiddle = computed(() => {
  return props.selectedCount > 0 && props.selectedCount < props.totalCount
})

const checkGroup = inject('checkbox-group', () => {
  return {
    injected: false,
    add() {
    },
    remove() {
    }
  }
});
</script>

<template>
  <div class="inline relative cursor-pointer">
    <div
      class="grow shrink basis-0 justify-start items-center gap-2 flex"
      @click="handle"
    >
      <div
        tabindex="0"
        class="outline-stone-800 w-6 h-6 justify-center items-center gap-3 flex"
        @keyup.enter="handle"
      >
        <div
          :class="{
            'bg-stone-500': isChecked || isMiddle,
            'bg-stone-100': !isChecked,
            'border-stone-400 border': !isChecked
          }"
          class="transition-all duration-200 w-6 h-6 p-1 rounded-lg justify-center items-center gap-2.5 flex"
        >
          <transition
            name="drop"
            class="transition-all duration-300"
            enter-from-class="opacity-0 -translate-y-5"
            enter-to-class=" opacity-1 translate-y-0"
            leave-from-class="opacity-1"
            leave-to-class="opacity-0 translate-y-5"
          >
            <img
              v-if="isChecked"
              alt=""
              src="/images/icons/check.svg"
              class="w-4 h-4 relative"
            >
            <img
              v-else-if="isMiddle"
              alt=""
              src="/images/icons/minus.svg"
              class="w-4 h-4 relative"
            >
          </transition>
        </div>
      </div>
      <div class="text-center text-stone-700 text-sm font-base leading-none select-none">
        <slot />
      </div>
    </div>
    <template v-if="hasErrorMessages">
      <div
        v-for="(message,ix) in (errorStore.get(props.field) || [])"
        :key="ix"
        class="text-orange-500 text-sm py-1 absolute top-full mt-2 min-w-max left-0"
      >
        {{ message }}
      </div>
    </template>
  </div>
</template>

<style scoped>
</style>
