import ModalDialog from "@/components/form/ModalDialog.vue";
import {createApp, h, ref} from "vue";
import {$t} from "@/i18n/useLanguage.js";

export default {
  install(app) {
    //app.component('ModalDialog', ModalDialog);
    app.config.globalProperties.$message = $message;
  }
}
let dialogInstance = null;
let confirmInstance = null;
export const $message = {
  success(message, options = {}) {
    $dialog(message, {
      title: '확인하기',
      ...options,
    })
  },
  error(message, options = {}) {
    $dialog(message, {
      title: '문제가 있어요',
      ...options,
    })
  }
}
/**
 * @typedef {Object} MessageOptions
 * @property {string} [title=''] - The title of the dialog.
 * @property {string} [message=''] - The message to be displayed in the dialog.
 * @property {string} [cancelLabel='취소'] - The label for the cancel button.
 * @property {string} [confirmLabel='확인'] - The label for the confirm button.
 * @property {Function} [reject=()=>{}] - The function to be executed when the dialog is rejected.
 * @property {Function} [resolve=()=>{}] - The function to be executed when the dialog is resolved.
 * @property {number} [duration=0] - The duration for which the dialog should be displayed.
 * @property {boolean} [visible=false] - Whether the dialog is visible or not.
 * @property {string|null} [imageUrl=null] - The URL of the image to be displayed in the dialog.
 *
 * @param message
 * @param {MessageOptions} options
 * @returns {Promise<unknown>}
 */
export function $confirm(message, options = {}) {
  const div = document.createElement('div');
  if(typeof message === 'object'){
    options = message;
  }

  if(typeof message === 'string'){
    options.message = message;
  }
  if(options.title === undefined){
    options.title = $t('확인');
  }
  if(options.cancelLabel === undefined){
    options.cancelLabel = $t('취소');
  }
  return new Promise((resolve, reject) => {
    const visible = ref(true);

    document.body.appendChild(div);
    // eslint-disable-next-line vue/one-component-per-file
    confirmInstance = createApp({
      render() {
        return h(ModalDialog, {
          visible: visible.value,
          onCancel: () => {
            visible.value = false;
            reject()
            setTimeout(() => {
              if(confirmInstance){
                confirmInstance.unmount();
                confirmInstance = null;
              }
              if(div){
                document.body.removeChild(div);
              }
            },301)
          },
          onConfirm: () => {
            visible.value = false;
            resolve();

            setTimeout(() => {
              if(confirmInstance){
                confirmInstance.unmount();
                confirmInstance = null;
              }
              if(document.body.contains(div)){
                document.body.removeChild(div);
              }
            },301)

          },
          ...options
        })
      }
    });
    confirmInstance.mount(div);
  });
}


export function $dialog(message, options = {}) {

  if(typeof document === 'undefined'){
    return;
  }

  if(typeof message === 'object'){
    options = message;
  }

  if(typeof message === 'string'){
    options.message = message;
  }

  // message 가 imageUrl 이라면
  if(message.match(/(http(s?):)([/|.\w\s-])*\.(?:jpg|gif|png)/g)){
    options.imageUrl = message;
  }

  options.title = options.title || $t('알림');

  const div = document.createElement('div');

  return new Promise((resolve) => {

    document.body.appendChild(div);
    // eslint-disable-next-line vue/one-component-per-file
    dialogInstance = createApp({
      mounted() {

      },
      render() {
         const visible = ref(true);
        return h(ModalDialog, {
          visible: true,
          cancelLabel: null,
          onClose: () => {

            visible.value = false;
            setTimeout(() => {
              if(dialogInstance){
                dialogInstance.unmount();
                dialogInstance = null;
              }
              if(div){
                document.body.removeChild(div);

              }
            },301)
            resolve();
          },
          ...options
        })
      }
    });
    dialogInstance.mount(div);
  });
}
