import axios from "@/utils/axios.js";
import {defineStore} from "pinia";
import {$t, useLanguage} from "@/i18n/useLanguage.js";
import {useLocalStorage} from '@vueuse/core'
import {$dialog} from "@/utils/pluginDialog.js";
import {config} from "vue-gtag";
import router from "@/routes/index.js";

export const useSessionStore = defineStore('session', {
  state: () => ({
    user: useLocalStorage('pinia/session/user', {}),
    language: useLocalStorage('pinia/session/language', 'kr'),
    currency: useLocalStorage('pinia/session/currency', 'krw'),
    cart_key: '',
    coupon_count: 0,
    notification_count: 0,
  }),
  getters: {
    getUser: (state) => {
      return state.user;
    },
    authenticated: (state) => {
      return state.user?.mb_id !== undefined;
    },
    getLanguage: (state) => {
      return state.language;
    },
    isKorean: (state) => {
      return state.language === 'kr';
    },
    getCurrency: (state) => {
      return state.currency;
    },
    isKrw: (state) => {
      return state.currency === 'krw';
    },
    isAdmin: (state) => {
      return state.user?.is_admin === true;
    },
    getCouponCount: (state) => {
      return state.coupon_count;
    },
    getNotificationCount: (state) => {
      return state.notification_count;
    },
  },
  // arrow 평션은 사용하지 않는다.
  actions: {
    async changeLanguage(lang, currency) {
      await axios.post('/api/v1/language', {lang: lang, currency: currency});
      const {locale} = useLanguage()
      locale.value =  lang;
    },
    async init() {
      try {
        const response = await axios.post('/api/v1/login-check');
        this.user = response.data.user;
        this.language = response.data.lang || 'kr';
        this.currency = response.data.currency;
        this.cart_key = response.data.cart_key;
        this.coupon_count = response.data.coupon_count;
        this.notification_count = response.data.notification_count;
        if (this.user?.mb_id) {
          config({userId: this.user.mb_id})
        }
      } catch (e) {
        this.user = {};
        this.language = 'kr';
        this.currency = 'krw';
        this.cart_key = '';
        this.coupon_count = 0;
        this.notification_count = 0;
      }

    },
    async logout() {
      router.push({name: 'Home'});
      await axios.post('/api/v1/logout');
      window.location.reload();
    },
    async login(data, redirect) {
      const response = await axios.post('/api/v1/login', data);
      this.user = response.data;
      if(redirect === undefined) {
        redirect = '/my-page';
      }
      router.push(redirect);
      window.location.reload();
    },
    async update(data) {
      const response = await axios.put('/api/v1/my-account/' + data.mb_id, data);
      await this.init();
      return response.data;
    },
    async updateAccount(data) {
      return await this.update(data);
    },
    async register(data) {
      const response = await axios.post('/api/v1/register', data);
      return response.data;
    },
    async unregister(data) {
      const response = await axios.post('/api/v1/unregister', data);
      await this.init();
      return response.data;
    },
    async forgotPassword(data) {
      const response = await axios.post('/api/v1/forget_password', data);
      return response.data;
    },
    async forgotId(data) {
      const response = await axios.post('/api/v1/forget_id', data);
      return response.data;
    },
    async resetPassword(data) {
      const response = await axios.post('/api/v1/reset_password', data);
      return response.data;
    },
    async changePassword(data){
      await axios.post("/api/v1/confirm-my-password", data)
      await this.init();
      $dialog($t("비밀번호가 변경되었습니다."), {
        title: $t("비밀번호 변경"),
        confirmLabel: $t("확인"),
      })

    },
  }
})
