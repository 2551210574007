import { createAppSSR } from "./main";
import * as Sentry from "@sentry/vue";
const { app, router, pinia } = createAppSSR();

router.isReady().then(() => {
  try {
    if (window.__INITIAL_STATE__) {
      pinia.state.value = JSON.parse(window.__INITIAL_STATE__);
      pinia.state.value.popup.readIds = JSON.parse(localStorage.getItem('pinia/popup/readId')) || [];
      pinia.state.value.session.language = localStorage.getItem('pinia/session/language') || 'ko';
      pinia.state.value.session.currency = localStorage.getItem('pinia/session/currency') || 'KRW';
    }
  } catch (e) {
    console.error(e);
  }
  //useLocalStorage 를 이용해 브라우저에 저장된 것을 가져와 덮어 씁니다.
  // pinia/popup/readId, pinia/session/language, pinia/session/currency

  app.mount("#app");
  if(window.location.host === 'candleworks.co.kr') {
    Sentry.init({
      app,
      dsn: 'https://67ff4353dcad46658819a40de6bcfa62@o281406.ingest.sentry.io/1506692',
      integrations: [
        Sentry.browserTracingIntegration({router}),
        //Sentry.replayIntegration(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // 번역:
      tracePropagationTargets: ["localhost", /^https:\/\/candleworks\.co\.kr\/api/, /^https:\/\/.*\.candleworks\.com\/api/],
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        //422 에러 무시
        'AxiosError',
        'Request failed with status code 405',
        'Request failed with status code 422',
        'Network Error',
        'Request aborted',
        'Non-Error promise rejection captured with value: undefined',
        'Failed to fetch dynamically imported module',
        /Unable to preload.*/,
        'Importing a module script failed'
      ]
    });
  }
  // 전역 에러 핸들링 설정
  window.addEventListener('error', (event) => {
    let Storage = localStorage || window.localStorage || document.localStorage || null ;
    if(!Storage){
      return;
    }
    if( Storage.getItem('errorHandler') ) {
      Storage.removeItem('errorHandler');
      return;
    }
    // Importing a module script failed 에러는 새로고침 안내
    // Failed to fetch dynamically imported module 에러는 새로고침 안내
    const errros = ['Failed to fetch dynamically imported module', 'Importing a module script failed'];
    if (errros.includes(event.message)) {
      alert('업데이트된 내용이 있습니다. 새로고침 해주세요.');
      Storage.setItem('errorHandler', 'true');
    }
  })
});
