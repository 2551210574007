<script setup>
import {errorStore} from "@/utils/useErrorStore.js";
import {inject, provide} from 'vue'
import IconicInput from "@/components/form/IconicInput.vue";

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  field: {
    type: String,
    default: ''
  },
  type:{
    type: String,
    default: 'text'
  },
  comment: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: ''
  },
  // 기본적으로 title 을 $t 로 번역하고, 번역이 안되면 title 을 그대로 출력한다.
  untranslated: {
    type: Boolean,
    default: false
  },
  unit:{
    type: String,
    default: ''
  }
})

provide('field', props.field)
/** @type {FormModel|null} */
const form = inject('formModel', null)
function hasErrorMessages(){
  return errorStore.get(props.field) && errorStore.get(props.field).length > 0
}

</script>

<template>
  <div
    :id="props.field + '_validate-error'"
    class="iconic-label-body w-full flex-col justify-center items-start lg:items-center lg:flex-row flex"
  >
    <div
      v-if="props.title"
      :style="{
        minWidth: (form?.labelMinWidth) ? form.labelMinWidth : ''
      }"
      class="py-3 justify-between items-center flex shrink lg:min-w-[120px] lg:max-w-[120px]"
    >
      <slot name="header">
        <div class="text-stone-700 text-lg lg:text-base font-semibold leading-none">
          {{ props.untranslated ? props.title : $t(props.title) }}
          <span
            v-if="props.required"
            class="text-red-500 text-base font-semibold leading-none"
          >*</span>
        </div>
      </slot>
    </div>
    <div class="w-full flex-col justify-center items-start flex relative">
      <slot>
        <template v-if="form">
          <iconic-input
            :type="props.type"
            :value="form.get(props.field)"
            :placeholder="props.placeholder"
            @update:value="(val)=>{form.updateValue(props.field, val)}"
          />
          <span
            v-if="unit"
            class="absolute right-4"
          >{{ unit }}</span>
        </template>
      </slot>
      <template v-if="hasErrorMessages">
        <div
          v-for="(message,ix) in (errorStore.get(props.field) || [])"
          :key="ix"
          class="text-orange-500 text-sm py-1 validate-error"
        >
          {{ message }}
        </div>
      </template>
      <slot name="comment" />
      <div
        v-if="props.comment"
        class="text-right py-1 text-stone-700 text-xs font-normal leading-3"
      >
        {{ props.comment }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
