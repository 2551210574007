<script setup>

import {computed, ref, provide, onMounted, h} from "vue";
import axios from "@/plugins/axios";
import {XCircleIcon} from "@heroicons/vue/24/outline";
const props = defineProps({
  id: {
    type: [Number, String],
    default: 0
  },
  name: {
    type: String,
    default: ''
  },
  closable: {
    type: Boolean,
    default: true
  }
})

const model = computed(() => {

  return page.value.template ?? {
    id: 0,
    tag: 'div',
    option: {},
    children: []
  }
})
const page = ref({});

function moveLocation() {
  if(page.value.link){
    window.open(page.value.link, '_blank');
  }
}
provide('$editor', {});
const fetch = async () => {
  const {data} = await axios.get('/api/cms/published/' + props.name)
  page.value = data;
}
onMounted(() => {
  fetch();
})

const visible = ref(true)

function hide() {
  visible.value = false;
}

function show() {
  visible.value = true;
}

const isVisible = computed(() => {
  if( typeof localStorage === 'undefined') return false;
  // 로컬스토리지에서 캐싱이 유효한지 확인한다.
  const date = localStorage.getItem('cms-page-' + props.name);
  if (date) {
    const now = new Date();
    if (now.getTime() < date) {
      return false;
    }else{
      // 캐싱이 유효하지 않으면 캐싱을 삭제한다.
      localStorage.removeItem('cms-page-' + props.name);
    }
  }
  return visible.value;
})
// LocalStorage 에 캐싱하고 오늘 24시 전까지는 보이지 않도록 한다.
function setCaching()
{
  const date = new Date();
  date.setHours(24,0,0,0);
  localStorage.setItem('cms-page-' + props.name, date.getTime());
}

function renderer(child){
  return h(child.tag ?? 'div', {
    id: child.id,
    class: child.option?.class,
    style: child.option?.style,
    innerHTML: child.option?.props?.innerText
  }, child.children?.map((child) => {
    return renderer(child);
  }))
}

defineExpose({
  model,
  hide,
  show
})
</script>

<template>
  <div
    v-if="closable"
    v-show="isVisible"
    class="relative"
  >
    <template
      v-for="child in page?.template?.children"
      :key="child.id"
    >
      <component
        :is="renderer(child)"
        class="cursor-pointer"
        @click="moveLocation"
      />
    </template>
    <div
      class="absolute right-0 top-0 flex items-center h-full p-4 cursor-pointer"
      @click="(e)=>{
        visible = false;
        setCaching();
      }"
    >
      <XCircleIcon class="w-8 text-white" />
    </div>
  </div>
  <div
    v-else
    v-show="isVisible"
  >
    <template
      v-for="child in page?.template?.children"
      :key="child.id"
    >
      <component
        :is="renderer(child)"
      />
    </template>
  </div>
</template>

<style scoped>

</style>
