/* eslint-disable no-unused-vars */
import {createApp} from 'vue'
import gTagPlugin from "@/plugins/gTagPlugin.js";

import {createRouterSSR} from './routes/index.js'
import PluginDialog from "@/utils/pluginDialog.js";
import {useSessionStore} from "@/utils/useSessionStore.js"
import {loadingDirective} from "@/utils/LoadingDirective.js";
import {languagePlugin} from "@/i18n/useLanguage.js";
import {iconicPlugin} from '@/plugins/IconicPlugin.js'
import * as Sentry from "@sentry/vue";

import {createPinia} from 'pinia'
import App from './App.vue'
import "./assets/css/tailwindcss.css"
import './style.css'
import ScreenHelper from "@/plugins/ScreenHelper.js";
import axios from "axios";

export function createAppSSR(){
  const router = createRouterSSR();
  const app = createApp(App);
  app.directive('loading', loadingDirective)

  const pinia = createPinia()
  app.use(pinia);
  app.use(ScreenHelper);
  app.use(PluginDialog);
  app.use(iconicPlugin);
  app.use(languagePlugin);
  app.use(gTagPlugin, router);

  app.use(router)
  return {app, router, pinia};
}
